import React, { Component } from 'react'
import { sign } from 'jsonwebtoken'

import { Grid } from "@material-ui/core";

class Metabase extends Component {
	render() {
    const section = {
      width: "100%",
      height: "100vh",
      left: 0,
      top: 0,
    };
		const mbSiteUrl = "https://metabase.backoffice.byebnk.com"
		const mbSecretKey = "81c29b027352bf6aa27cc3a42097255f6876f5401826ee863e754f0110ecd37a"
    const payload = {
      resource: { dashboard: 15 },
      params: {
        "cotista": this.props.cotista,
        "fundo": "fe014f30-a202-48d3-9e7d-aacb9fb490c3"
      },
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
		const token = sign(payload, mbSecretKey)
		const iframeUrl = `${mbSiteUrl}/embed/dashboard/${token}#theme=night&bordered=false&titled=true`
		return (
      <div style={section}>
        <iframe src={iframeUrl} title='Metabase' style={{ border: 'none', width: '100%', height: '100%' }} />
      </div>
    )
  }
}

export default Metabase
